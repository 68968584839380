import {
  getHotDeals,
  getOzBargainDeals,
  getPopular,
  getTopTemuDeals,
} from '@web/lib/api/products';
import SEO from '@web/components/SEO';
import dynamic from 'next/dynamic';
import { Box, Container } from '@mui/material';

const AdSenseAd = dynamic(
  () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    import('@web/components/AdSenseAd', { ssr: false })
);
const ShopLayout = dynamic(
  () => import('../src/components/layouts/ShopLayout')
);
const ProductList = dynamic(
  () => import('@web/components/product-list/ProductList')
);
const ProductCard = dynamic(
  () => import('@web/components/product-cards/ProductCard')
);

const CATEGORIES = [
  'Electronics',
  'Home & Appliances',
  'Fashion',
  'Grocery',
  'Baby & Kids',
  'Pets',
  'Entertainment',
  'Home Improvement & Garden',
  'Health & Beauty',
  'Sports & Outdoor',
  'New Arrivals',
];
export const getStaticProps = async () => {
  const startTime = Date.now();
  const [hotDeals, ozBargainDeals] = await Promise.all([
    getHotDeals(),
    getOzBargainDeals(),
  ]);
  const endTime = Date.now();
  console.log(`[${endTime - startTime}ms] Home page`);
  return {
    props: {
      hotDeals,
      ozBargainDeals,
      countryCode: process.env.COUNTRY_CODE,
    },
    revalidate: 7200,
  };
};

export default function Home(props) {
  return (
    <ShopLayout>
      <SEO
        title="Price History, Comparison & Tracking | Save Money on Your Purchases"
        description="Track price history, compare prices, and set price alerts for products across 20,000+ online shops. BuyWisely helps you find the best deals and make informed buying decisions."
      />
      {props.countryCode === 'au' && (
        <ProductList
          products={props.popularProducts || []}
          title="Popular Products"
          numberPerRow={6}
          horizontal={true}
          link="/product/popular"
          ProductCard={ProductCard}
        />
      )}
      {props.countryCode === 'au' && props.ozBargainDeals.length && (
        <ProductList
          products={props.ozBargainDeals || []}
          title="OzBargain Deals"
          numberPerRow={4}
          horizontal={true}
          ProductCard={ProductCard}
          link="/product/ozbargain"
          height={400}
        />
      )}
      {CATEGORIES.map(
        (category, index) =>
          props.hotDeals[category] && (
            <Box key={index}>
              <ProductList
                products={props.hotDeals[category] || []}
                category={category}
                numberPerRow={6}
                horizontal={true}
                ProductCard={ProductCard}
              />
            </Box>
          )
      )}
    </ShopLayout>
  );
}
